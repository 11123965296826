<script>
import { mapGetters, mapMutations } from 'vuex';
import stepsMixin from '@/mixins/stepsMixin'
export default {
	mixins: [stepsMixin],
	props: ['client'],
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
	},
	data() {
		return {
			indexSelected: 0,
			labels: {
				balance: {
					assets: {
						non_current_assets: 'NON-CURRENT ASSETS', 
						property_plant_equipment: 'Property plant & Equipment', 
						intangible_assets: 'Intagible Assets', 
						investments: 'Investments', 
						financial_assets_lt: 'Financial assets LT', 
						goodwill: 'Goodwill', 
						deferrend_tax_assets: 'Defereed taxt assets', 
						current_assets: 'CURRENT ASSETS', 
						inventories: 'Inventories', 
						trade_accounts_receivable: 'Trade accounts receivable', 
						related_companies: 'Releated Companies', 
						other_debtors: 'Other debtors', 
						other_current_assets: 'Other current assets', 
						st_investment: 'ST Investments', 
						cash_and_cash_equivalents: 'Cash and cash equivalents', 
						total_assets: 'TOTAL ASSETS', 
					},
					liabilities: {
						net_worth: 'NET WORTH',
						common_stock: 'Common Stock',
						reserves_and_other_equity: 'Reserves and Other Equity',
						minority_interests: 'Minority interests',
						non_current_liabilities: 'NON-CURRENT LIABILITIES',
						provisions_and_deferred_income_lt: 'Provisions and Deferred Income LT',
						bank_bonds_derivates_leases_lt: 'Bank + Bonds + Derivates + Leases LT',
						bank_bonds_derivates_leases_st: 'Bank + Bonds + Derivates + Leases ST',
						related_companies_st: 'Related companies ST',
						trade_accounts_payable: 'Trade accounts Payable',
						other_st: 'Other ST',
						total_equity_liabilities: 'TOTAL EQUITY & LIABILITIES',
					}
				},
				income: {
					total_revenues: 'TOTAL REVENUES',
					ebitda: 'EBITAD',
					net_operating_income_ebit: 'NET OPERATING INCOME / EBIT',
					interest_expense: 'Interest Expense',
					equity_accounted_investment_results: 'Equity accounted investments results',
					net_financial_results: 'NET FINANCIAL RESULTS',
					income_cont_operations: 'INCOME CONT. OPERATIONS',
					net_other_results: 'Net other results',
					income_before_taxe_discn_oper: 'INCOME (before taxes & discn. oper.)',
					income_taxes: 'Income taxes',
					net_discontinued_operations_result: 'Net discountinued operations result',
					net_income_after_taxes_disc_oper: 'NET INCOME (after taxes and discoper.)',
					net_income_atributable: 'NET INCOME Atributable',
				},
				cash_flow: {
					funds_form_operations: 'Founds Form Operations',
					change_in_working_capital: 'Change in Working Capital',
					dividends_received_oper: 'Dividends received (oper)',
					cash_flow_operating_act_cfo: 'CASH FLOW FROM OPERATING ACT. (CFO)',
					capex: 'Capex',
					investments_divestments: 'Investment / Divestments',
					dividends_received_invest: 'Divestment received (invest)',
					other_cash_flow_inesting_act: 'Other cash flow investing act',
					cash_flow_investing_act_cfi: 'CASH FLOW INVESTING ACT. (CFI)',
					net_changes_financial_debt: 'Net Change Financial Debit',
					capital_increase: 'Capital Increase',
					dividends: 'Dividends',
					share_buyback: 'Share buyback',
					other_cash_flow_financing_act: 'Other cash flow financing act.',
					cash_flow_financing_act_cff: 'CASH FLOW FINANCING ACT. (CFF)',
					total_movements_in_cash: 'Total movements in cash',
				},
				solvency: {
					gross_financial_debt_to_ebitad_adj_x: 'Gross Financial Debt to Ebitda adj. (x)',
					net_financial_debt_to_ebitad_adj_x: 'Net Financial Debt to Ebitda adj, (x)',
					ebitad_adj_interest_expenses: 'Ebitda adj. / Interest exprese (x)',
					gif_to_gross_financial_debt: 'GIF to Gross Financial Debt (%)',
					gif_to_net_financial_debt: 'GIF to Net Fiancial Debt (%)',
					ebitad_adj_fixed_charge: 'Ebitda adj. / Fixed charge (x)',
				},
				liquidity: {
					net_worth_total_asset: 'Net Worth / Total Asset (%)',
					long_term_debt_net_worth: 'Long term debt to Net Worth (%)',
					c_asset_c_liabilities: 'C. Asset / C. Liabilities (x)',
					fixed_asset_net_worth: 'Fixed Asset / Net Worth (%)',
					gross_financial_debt_to_net_worth: 'Gross Financial Debt to Net Worth (%)',
				},
				profitability: {
					ebitda_adj_margin: 'Ebitda adj. margin (%)',
					roa: 'R.O.A',
					roe: 'R.O.E',
					roce: 'R.O.C.E',
				},
				activity: {
					avg_days_sale_out: 'Avg. Days Sales Out.',
					avg_days_inventory_out: 'Avg. Days Inventory Out.',
					avg_days_payable_out: 'Avg. Days Payable Out.',
					avg_period_to_maturity_days: 'Avg. period to maturity (days)',
				}
			},
			formData: {
				ffss: [
					{
						ffss_id: '00000000000000000543895',
						ffss_close_by: 'E023754',
						ffss_confirmed_by: 'E023754',
						ffss_date: '2022-06-30',
						original_currency: 'MXN',
						consolotation_type: 'Individual',
						accounting_adjustment: 'No',
						ffss_cetification_type: 'FISCAL',
						audit_opinion: '',
						auditor_name: 'INTERNO',
						period: '6M',
						commnets_on_certification_type_consolidation_perimenter_accounting_adjustment: 'AJUSTE LEASING',
						balance: {
							assets: {
								non_current_assets: 1902961000, 
								property_plant_equipment: 1344295000, 
								intangible_assets: 0, 
								investments: 0, 
								financial_assets_lt: 558666000, 
								goodwill: 0, 
								deferrend_tax_assets: 0, 
								current_assets: 890912000, 
								inventories: 7037000, 
								trade_accounts_receivable: 7037000, 
								related_companies: 37534000, 
								other_debtors: 509402000, 
								other_current_assets: 4809000, 
								st_investment: 0, 
								cash_and_cash_equivalents: 7706000, 
								total_assets: 2793873000, 
							},
							liabilities: {
								net_worth: 501549000,
								common_stock: 96837000,
								reserves_and_other_equity: 404712000,
								minority_interests: 0,
								non_current_liabilities: 964749000,
								provisions_and_deferred_income_lt: 380721000,
								bank_bonds_derivates_leases_lt: 584028000,
								bank_bonds_derivates_leases_st: 585983000,
								related_companies_st: 172279000,
								trade_accounts_payable: 478030000,
								other_st: 91283000,
								total_equity_liabilities: 2793873000,
							}
						},
						income: {
							total_revenues: 99889000,
							ebitda: 63765000,
							net_operating_income_ebit: -32676000,
							interest_expense: 46781000,
							equity_accounted_investment_results: 0,
							net_financial_results: -48305000,
							income_cont_operations: -80981000,
							net_other_results: 0,
							income_before_taxe_discn_oper: -80981000,
							income_taxes: 0,
							net_discontinued_operations_result: 0,
							net_income_after_taxes_disc_oper: -80981000,
							net_income_atributable: -80981000,
						},
						cash_flow: {
							funds_form_operations: 0,
							change_in_working_capital: 0,
							dividends_received_oper: 0,
							cash_flow_operating_act_cfo: 0,
							capex: 0,
							investments_divestments: 0,
							dividends_received_invest: 0,
							other_cash_flow_inesting_act: 0,
							cash_flow_investing_act_cfi: 0,
							net_changes_financial_debt: 0,
							capital_increase: 0,
							dividends: 0,
							share_buyback: 0,
							other_cash_flow_financing_act: 0,
							cash_flow_financing_act_cff: 0,
							total_movements_in_cash: 0,
						},
						solvency: {
							gross_financial_debt_to_ebitad_adj_x: 9.17,
							net_financial_debt_to_ebitad_adj_x: 9.11,
							ebitad_adj_interest_expenses: 1.36,
							gif_to_gross_financial_debt: 19,
							gif_to_net_financial_debt: 20,
							ebitad_adj_fixed_charge: 0.19,
						},
						liquidity: {
							net_worth_total_asset: 18,
							long_term_debt_net_worth: 116,
							c_asset_c_liabilities: 0.67,
							fixed_asset_net_worth: 379,
							gross_financial_debt_to_net_worth: 2.33,
						},
						profitability: {
							ebitda_adj_margin: 6,
							roa: -6,
							roe: 32,
							roce: -4,
						},
						activity: {
							avg_days_sale_out: 59.39,
							avg_days_inventory_out: 1.64,
							avg_days_payable_out: 111.07,
							avg_period_to_maturity_days: 50.05,
						}
					}
				],
				// Capital Structure
				debt_maturity_profile: 'Negative',
				market_risk: '7.5',
				counter_party_credit_bank: '7.5',
				capital_structure_assessment: '...',
				// Off-Balance Sheet
				off_balance_sheet_risk: 8,
				off_balance_sheet_assessment: 'El cliente no registra en sus estados financieros la deuda por arrendamiento puro',
				// Financial Flexibility and Liquidity
				access_to_additional_founding_or_capital: 'Average',
				need_for_additional_founding_or_capital: 'Very High',
				financial_flexibility_liquidity_score: '8.5',
				financial_flexibility_liquidity_score_assessment: '...',
				undrawn_debt: 'NO',
				
			}
		}
	},

	computed: {
		...mapGetters({
			'ratingSelected': 'arceRatings/selected'
		})
	},

	
	methods: {
		calcFill() {
			let fields = Object.keys(this.form)
			if(this.form.multipleGeografia == 'NO') {
				let index = fields.indexOf('comentariosMultipleGeografia')
				fields.splice(index, 1)
			}
			if(this.form.multipleActividadEconomica == 'NO') {
				let index = fields.indexOf('comentariosMultipleActividadEconomica')
				fields.splice(index, 1)
			}
			let points = 100 / fields.length

			let fill = 0

			fields.forEach(f => {
				if(this.form[f]) {
					fill += points
				}
			})

			return Math.round(fill)
		},
		save() {
			let vm = this
			let payload =  {
				key: 'financial_risk',
				form: {...this.formData},
				data: {},
			}
			vm.updateAnalysis(payload)
			this.hideModal('#formFinancialRiskModal')
		},
		showModal(id) {
			if(document.querySelectorAll(`${id}`).length > 1) {
				$(`body > ${id}`).remove()
				this.showModal(id)
			} else {
				$(id).appendTo("body").modal('show')

			}
		},
		hideModal(id) {
			if(document.querySelectorAll(`${id}`).length > 1) {
				$(`body > ${id}`).modal('hide')
			}
		},
		closeModal() {
			this.updateFormData()
		},
		updateFormData() {
			let formData = JSON.parse(JSON.stringify(this.ratingSelected.analysis.financial_risk.form))
			this.$set(this, 'formData', formData)
		},
		...mapMutations({
			updateAnalysis: 'arceRatings/updateAnalysis'
		}) 
		
	},
	mounted() {
		this.updateFormData()
		$('#formFinancialRiskModal').on('hide.bs.modal', this.closeModal)
	},

	beforeDestroy() {
		$('#formFinancialRiskModal').off('hide.bs.modal', this.closeModal)
	}
	
}
</script>
<template>
	<div style="">
		<div class="text-right py-2">
			<button class="s-btn s-btn--primary" @click="showModal('#formFinancialRiskModal')">
				Edit
			</button>
		</div>
		<div  v-if="formData" class="py-3">
			<div class="mt-2 s-collapse__header collapsed" data-toggle="collapse" data-target="#c_fr_n_1" aria-expanded="true" aria-controls="c_fr_n_1">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Financial Statement</span></h6>
				</div>
			</div>
			
			<div id="c_fr_n_1" class="collapse show" aria-labelledby="c_fr_n_1">
				<div class="mt-2 s-collapse__header collapsed" data-toggle="collapse" data-target="#c_fr_n_1_1" aria-expanded="true" aria-controls="c_fr_n_1_1">
					<div class="d-flex">
						<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>GENERAL INFORMATION (ANCHOR RATING)</span></h6>
					</div>
				</div>
				
				<div id="c_fr_n_1_1" class="collapse show" aria-labelledby="c_fr_n_1_1">
					<div class="d-flex flex-wrap">
						<div class="col-4 py-3">
							<p class="m-0">FFSS id</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.ffss[indexSelected].ffss_id">
						</div>
						<div class="col-4 py-3">
							<p class="m-0">FFSS Closed By</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.ffss[indexSelected].ffss_close_by">
						</div>
						<div class="col-4 py-3">
							<p class="m-0">FFSS Confirmed By</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.ffss[indexSelected].ffss_confirmed_by">
						</div>
						<div class="col-4 py-3">
							<p class="m-0">FFSS Date</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.ffss[indexSelected].ffss_date">
						</div>
						<div class="col-4 py-3">
							<p class="m-0">Original Currency</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.ffss[indexSelected].original_currency">
						</div>
						<div class="col-4 py-3">
							
						</div>
						<div class="col-4 py-3">
							<p class="m-0">Consolitation Type</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.ffss[indexSelected].consolotation_type">
						</div>
						<div class="col-4 py-3">
							<p class="m-0">Accounting adjustment</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.ffss[indexSelected].accounting_adjustment">
						</div>
						<div class="col-4 py-3">
							
						</div>
						<div class="col-4 py-3">
							<p class="m-0">FFSS Certification Type</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.ffss[indexSelected].ffss_cetification_type">
						</div>
						<div class="col-4 py-3">
							<p class="m-0">Audit opinion</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.ffss[indexSelected].audit_opinion">
						</div>
						<div class="col-4 py-3">
							<p class="m-0">Audior name</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.ffss[indexSelected].auditor_name">
						</div>
						<div class="col-4 py-3">
							<p class="m-0">COMMENTS ON: CERTIFICATION TYPE, CONSOLIDATION PERIMETER, ACCOUNTING ADJUSTMENT</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.ffss[indexSelected].commnets_on_certification_type_consolidation_perimenter_accounting_adjustment">
						</div>
					</div>
				</div>

				<div class="mt-2 s-collapse__header collapsed" data-toggle="collapse" data-target="#c_fr_n_1_2" aria-expanded="true" aria-controls="c_fr_n_1_2">
					<div class="d-flex">
						<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>REPORTED FINANCIAL SUMMARY</span></h6>
					</div>
				</div>
				
				<div id="c_fr_n_1_2" class="collapse show" aria-labelledby="c_fr_n_1_2">
					<div class="d-flex">
						<div class="btn-group ml-auto" role="group" aria-label="Basic example">
							<button type="button" class="btn btn-sm btn-primary">MXN</button>
							<button type="button" class="btn btn-sm btn-link">EUR</button>
							<button type="button" class="btn btn-sm btn-link">USD</button>
						</div>
						<div class="btn-group" role="group" aria-label="Basic example">
							<button type="button" class="btn btn-sm btn-primary">Units</button>
							<button type="button" class="btn btn-sm btn-link">Thousands</button>
							<button type="button" class="btn btn-sm btn-link">Millions</button>
							<button type="button" class="btn btn-sm btn-link">Billions</button>
						</div>
					</div>
					<div class="px-3">
						<h6 class="subtitle">Balance Sheet Summary</h6>
					</div>
					<div class="s-table">
						<table class="s-table col-12">
							<thead>
								<tr>
									<th></th>
									<template v-for="(ffss, index) in ratingSelected.analysis.financial_risk.form.ffss">
										<th class="text-center uppercase" style="width: 300px">
											{{  index + 1}}° {{ ffss.ffss_date }} {{ ffss.ffss_cetification_type }} {{ ffss.consolotation_type }}
										</th>
										<th>
										</th>
									</template>
									
								</tr>
								<tr>
									<th></th>
									<template v-for="(ffss, index) in ratingSelected.analysis.financial_risk.form.ffss">
										<th class="text-right uppercase">
											{{ ffss.period }} PARTIAL
										</th>
										<th class="text-right">
											-
										</th>
									</template>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(row, index) in labels.balance.assets">
									<td>{{ row }}</td>
									<template v-for="(ffss, indexf) in ratingSelected.analysis.financial_risk.form.ffss">
										<td class="text-right">
											{{ ffss.balance.assets[index] | numeral('0,0') }} 
										</td>
										<td class="text-right">
											%
										</td>
									</template>
								</tr>
							</tbody>
						</table>
					</div>
					<!-- liabilities -->
					<div class="s-table mt-5">
						<table class="s-table col-12">
							<thead>
								<tr>
									<th></th>
									<template v-for="(ffss, index) in ratingSelected.analysis.financial_risk.form.ffss">
										<th class="text-center uppercase" style="width: 300px">
											{{  index + 1}}° {{ ffss.ffss_date }} {{ ffss.ffss_cetification_type }} {{ ffss.consolotation_type }}
										</th>
										<th>
										</th>
									</template>
									
								</tr>
								
							</thead>
							<tbody>
								<tr v-for="(row, index) in labels.balance.liabilities">
									<td>{{ row }}</td>
									<template v-for="(ffss, indexf) in ratingSelected.analysis.financial_risk.form.ffss">
										<td class="text-right">
											{{ ffss.balance.liabilities[index] | numeral('0,0') }} 
										</td>
										<td class="text-right">
											%
										</td>
									</template>
								</tr>
							</tbody>
						</table>
					</div>

					<div class="px-3 mt-4">
						<h6 class="subtitle mb-0">Income Statement</h6>
					</div>
					<div class="s-table mt-2">
						<table class="s-table col-12">
							<thead>
								<tr>
									<th></th>
									<template v-for="(ffss, index) in ratingSelected.analysis.financial_risk.form.ffss">
										<th class="text-center uppercase" style="width: 300px">
											{{  index + 1}}° {{ ffss.ffss_date }} {{ ffss.ffss_cetification_type }} {{ ffss.consolotation_type }}
										</th>
										<th>
										</th>
									</template>
								</tr>
								<tr>
									<th></th>
									<template v-for="(ffss, index) in ratingSelected.analysis.financial_risk.form.ffss">
										<th class="text-right uppercase">
											{{ ffss.period }} PARTIAL
										</th>
										<th class="text-right">
											-
										</th>
									</template>
								</tr>
								
							</thead>
							<tbody>
								<tr v-for="(row, index) in labels.income">
									<td>{{ row }}</td>
									<template v-for="(ffss, indexf) in ratingSelected.analysis.financial_risk.form.ffss">
										<td class="text-right">
											{{ ffss.income[index] | numeral('0,0') }} 
										</td>
										<td class="text-right">
											%
										</td>
									</template>
								</tr>
							</tbody>
						</table>
					</div>
					
					<div class="px-3 mt-4">
						<h6 class="subtitle mb-0">Cash flow analysis</h6>
					</div>
					<div class="s-table mt-2">
						<table class="s-table col-12">
							<thead>
								<tr>
									<th></th>
									<template v-for="(ffss, index) in ratingSelected.analysis.financial_risk.form.ffss">
										<th class="text-center uppercase" style="width: 300px">
											{{  index + 1}}° {{ ffss.ffss_date }} {{ ffss.ffss_cetification_type }} {{ ffss.consolotation_type }}
										</th>
										<th>
										</th>
									</template>
								</tr>
								<tr>
									<th></th>
									<template v-for="(ffss, index) in ratingSelected.analysis.financial_risk.form.ffss">
										<th class="text-right uppercase">
											{{ ffss.period }} PARTIAL
										</th>
										<th class="text-right">
											-
										</th>
									</template>
								</tr>
								
							</thead>
							<tbody>
								<tr v-for="(row, index) in labels.cash_flow">
									<td>{{ row }}</td>
									<template v-for="(ffss, indexf) in ratingSelected.analysis.financial_risk.form.ffss">
										<td class="text-right">
											{{ ffss.cash_flow[index] | numeral('0,0') }} 
										</td>
										<td class="text-right">
											%
										</td>
									</template>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div class="mt-2 s-collapse__header collapsed" data-toggle="collapse" data-target="#c_fr_n_2" aria-expanded="true" aria-controls="c_fr_n_2">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Key Metrics Corporate</span></h6>
				</div>
			</div>
			
			<div id="c_fr_n_2" class="collapse show" aria-labelledby="c_fr_n_2">

				<div class="s-collapse__header collapsed mt-3" data-toggle="collapse" data-target="#c_fr_n_2_1" aria-expanded="true" aria-controls="c_fr_n_2_1">
					<div class="d-flex">
						<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>SOLVENCY</span></h6>
					</div>
				</div>
				
				<div id="c_fr_n_2_1" class="collapse show" aria-labelledby="c_fr_n_2_1">
					<div class="s-table mt-2">
						<table class="s-table col-12">
							<thead>
								<tr>
									<th></th>
									<template v-for="(ffss, index) in ratingSelected.analysis.financial_risk.form.ffss">
										<th class="text-center uppercase" style="width: 300px">
											{{  index + 1}}° {{ ffss.ffss_date }} {{ ffss.ffss_cetification_type }} {{ ffss.consolotation_type }}
										</th>
										<th>
										</th>
									</template>
									
								</tr>
								
							</thead>
							<tbody>
								<tr v-for="(row, index) in labels.solvency">
									<td>{{ row }}</td>
									<template v-for="(ffss, indexf) in ratingSelected.analysis.financial_risk.form.ffss">
										<td class="text-right">
											{{ ffss.solvency[index] | numeral('0,0') }} 
										</td>
										<td class="text-right">
											%
										</td>
									</template>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				
				<div class="s-collapse__header collapsed mt-3" data-toggle="collapse" data-target="#c_fr_n_2_2" aria-expanded="true" aria-controls="c_fr_n_2_2">
					<div class="d-flex">
						<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>LIQUIDITY</span></h6>
					</div>
				</div>
				
				<div id="c_fr_n_2_2" class="collapse show" aria-labelledby="c_fr_n_2_2">
					<div class="s-table mt-2">
						<table class="s-table col-12">
							<thead>
								<tr>
									<th></th>
									<template v-for="(ffss, index) in ratingSelected.analysis.financial_risk.form.ffss">
										<th class="text-center uppercase" style="width: 300px">
											{{  index + 1}}° {{ ffss.ffss_date }} {{ ffss.ffss_cetification_type }} {{ ffss.consolotation_type }}
										</th>
									</template>
									
								</tr>
								
							</thead>
							<tbody>
								<tr v-for="(row, index) in labels.liquidity">
									<td>{{ row }}</td>
									<template v-for="(ffss, indexf) in ratingSelected.analysis.financial_risk.form.ffss">
										<td class="text-right">
											{{ ffss.liquidity[index] | numeral('0,0') }} 
										</td>
										
									</template>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				
				<div class="s-collapse__header collapsed mt-3" data-toggle="collapse" data-target="#c_fr_n_2_3" aria-expanded="true" aria-controls="c_fr_n_2_3">
					<div class="d-flex">
						<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>PROFITABILITY</span></h6>
					</div>
				</div>
				
				<div id="c_fr_n_2_3" class="collapse show" aria-labelledby="c_fr_n_2_3">
					<div class="s-table mt-2">
						<table class="s-table col-12">
							<thead>
								<tr>
									<th></th>
									<template v-for="(ffss, index) in ratingSelected.analysis.financial_risk.form.ffss">
										<th class="text-center uppercase" style="width: 300px">
											{{  index + 1}}° {{ ffss.ffss_date }} {{ ffss.ffss_cetification_type }} {{ ffss.consolotation_type }}
										</th>
										
									</template>
									
								</tr>
								
							</thead>
							<tbody>
								<tr v-for="(row, index) in labels.profitability">
									<td>{{ row }}</td>
									<template v-for="(ffss, indexf) in ratingSelected.analysis.financial_risk.form.ffss">
										<td class="text-right">
											{{ ffss.profitability[index] | numeral('0,0') }} 
										</td>
										
									</template>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				
				<div class="s-collapse__header collapsed mt-3" data-toggle="collapse" data-target="#c_fr_n_2_4" aria-expanded="true" aria-controls="c_fr_n_2_4">
					<div class="d-flex">
						<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>ACTIVITY</span></h6>
					</div>
				</div>
				
				<div id="c_fr_n_2_4" class="collapse show" aria-labelledby="c_fr_n_2_4">
					<div class="s-table mt-2">
						<table class="s-table col-12">
							<thead>
								<tr>
									<th></th>
									<template v-for="(ffss, index) in ratingSelected.analysis.financial_risk.form.ffss">
										<th class="text-center uppercase" style="width: 300px">
											{{  index + 1}}° {{ ffss.ffss_date }} {{ ffss.ffss_cetification_type }} {{ ffss.consolotation_type }}
										</th>
										
									</template>
									
								</tr>
								
							</thead>
							<tbody>
								<tr v-for="(row, index) in labels.activity">
									<td>{{ row }}</td>
									<template v-for="(ffss, indexf) in ratingSelected.analysis.financial_risk.form.ffss">
										<td class="text-right">
											{{ ffss.activity[index] | numeral('0,0') }} 
										</td>
										
									</template>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

			</div>


			<div class="mt-2 s-collapse__header collapsed" data-toggle="collapse" data-target="#c_fr_n_3" aria-expanded="true" aria-controls="c_fr_n_3">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Capital Structure</span></h6>
				</div>
			</div>
			
			<div id="c_fr_n_3" class="collapse show" aria-labelledby="c_fr_n_3">
				<div class="d-flex flex-wrap">
					<div class="col-4 py-3">
						<p class="m-0">Debit maturity profile</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.debt_maturity_profile">
					</div>
					<div class="col-4 py-3">
						<p class="m-0">Market Risk</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.market_risk">
					</div>
					<div class="col-4 py-3">
						<p class="m-0">Counter Party Credit Risk</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.counter_party_credit_bank">
					</div>
					<div class="col-4 py-3">
						<p class="m-0">Copital Structure Assessment</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.capital_structure_assessment">
					</div>
				</div>
			</div>
			<div class="mt-2 s-collapse__header collapsed" data-toggle="collapse" data-target="#c_fr_n_4" aria-expanded="true" aria-controls="c_fr_n_4">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Off-Balance Sheet</span></h6>
				</div>
			</div>
			
			<div id="c_fr_n_4" class="collapse show" aria-labelledby="c_fr_n_4">
				<div class="d-flex flex-wrap">
					<div class="col-4 py-3">
						<p class="m-0">OFF Blance Sheet Risk</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.off_balance_sheet_risk">
					</div>
					<div class="col-12 py-3">
						<p class="m-0">Off Balance Sheet Assessment</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.off_balance_sheet_assessment">
					</div>
				</div>
			</div>
			<div class="mt-2 s-collapse__header collapsed" data-toggle="collapse" data-target="#c_fr_n_5" aria-expanded="true" aria-controls="c_fr_n_5">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Financial Flexibility and Liquidity</span></h6>
				</div>
			</div>
			
			<div id="c_fr_n_5" class="collapse show" aria-labelledby="c_fr_n_5">
				<div class="d-flex flex-wrap">
					<div class="col-4 py-3">
						<p class="m-0">Access to Additional Funding or Capital</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.access_to_additional_founding_or_capital">
					</div>
					<div class="col-4 py-3">
						<p class="m-0">Need to Additional Funding or Capital</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.need_for_additional_founding_or_capital">
					</div>
				</div>
				<div class="d-flex flex-wrap">
					<div class="col-4 py-3">
						<p class="m-0">Financial Flebility & Liquidity Score</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.financial_flexibility_liquidity_score">
					</div>
				</div>
				<div class="d-flex flex-wrap">
					<div class="col-4 py-3">
						<p class="m-0">Financial Flebility & Liquidity Score Assessment</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.financial_flexibility_liquidity_score_assessment">
					</div>
				</div>
				<div class="d-flex flex-wrap">
					<div class="col-4 py-3">
						<p class="m-0">Undrawn debt</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.undrawn_debt">
					</div>
				</div>
			</div>
			
		</div>
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<div class="modal s-modal fade" style="font-size: 0.8em" id="formFinancialRiskModal" tabindex="-1" role="dialog" aria-labelledby="formFinancialRiskModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl " role="document">
				<div class="modal-content">
				<div class="modal-header p-0">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div  v-if="formData" class="py-3">
						<div class="mt-2 s-collapse__header collapsed" data-toggle="collapse" data-target="#c_fr_m_1" aria-expanded="true" aria-controls="c_fr_m_1">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Financial Statement</span></h6>
							</div>
						</div>
						
						<div id="c_fr_m_1" class="collapse show" aria-labelledby="c_fr_m_1">
							<div class="mt-2 s-collapse__header collapsed" data-toggle="collapse" data-target="#c_fr_m_1_1" aria-expanded="true" aria-controls="c_fr_m_1_1">
								<div class="d-flex">
									<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>GENERAL INFORMATION (ANCHOR RATING)</span></h6>
								</div>
							</div>
							
							<div id="c_fr_m_1_1" class="collapse show" aria-labelledby="c_fr_m_1_1">
								<div class="d-flex flex-wrap">
									<div class="col-4 py-3">
										<p class="m-0">FFSS id</p>
										<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.ffss[indexSelected].ffss_id">
									</div>
									<div class="col-4 py-3">
										<p class="m-0">FFSS Closed By</p>
										<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.ffss[indexSelected].ffss_close_by">
									</div>
									<div class="col-4 py-3">
										<p class="m-0">FFSS Confirmed By</p>
										<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.ffss[indexSelected].ffss_confirmed_by">
									</div>
									<div class="col-4 py-3">
										<p class="m-0">FFSS Date</p>
										<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.ffss[indexSelected].ffss_date">
									</div>
									<div class="col-4 py-3">
										<p class="m-0">Original Currency</p>
										<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.ffss[indexSelected].original_currency">
									</div>
									<div class="col-4 py-3">
										
									</div>
									<div class="col-4 py-3">
										<p class="m-0">Consolitation Type</p>
										<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.ffss[indexSelected].consolotation_type">
									</div>
									<div class="col-4 py-3">
										<p class="m-0">Accounting adjustment</p>
										<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.ffss[indexSelected].accounting_adjustment">
									</div>
									<div class="col-4 py-3">
										
									</div>
									<div class="col-4 py-3">
										<p class="m-0">FFSS Certification Type</p>
										<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.ffss[indexSelected].ffss_cetification_type">
									</div>
									<div class="col-4 py-3">
										<p class="m-0">Audit opinion</p>
										<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.ffss[indexSelected].audit_opinion">
									</div>
									<div class="col-4 py-3">
										<p class="m-0">Audior name</p>
										<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.ffss[indexSelected].auditor_name">
									</div>
									<div class="col-4 py-3">
										<p class="m-0">COMMENTS ON: CERTIFICATION TYPE, CONSOLIDATION PERIMETER, ACCOUNTING ADJUSTMENT</p>
										<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.financial_risk.form.ffss[indexSelected].commnets_on_certification_type_consolidation_perimenter_accounting_adjustment">
									</div>
								</div>
							</div>

							<div class="mt-2 s-collapse__header collapsed" data-toggle="collapse" data-target="#c_fr_m_1_2" aria-expanded="true" aria-controls="c_fr_m_1_2">
								<div class="d-flex">
									<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>REPORTED FINANCIAL SUMMARY</span></h6>
								</div>
							</div>
							
							<div id="c_fr_m_1_2" class="collapse show" aria-labelledby="c_fr_m_1_2">
								<div class="d-flex">
									<div class="btn-group ml-auto" role="group" aria-label="Basic example">
										<button type="button" class="btn btn-sm btn-primary">MXN</button>
										<button type="button" class="btn btn-sm btn-link">EUR</button>
										<button type="button" class="btn btn-sm btn-link">USD</button>
									</div>
									<div class="btn-group" role="group" aria-label="Basic example">
										<button type="button" class="btn btn-sm btn-primary">Units</button>
										<button type="button" class="btn btn-sm btn-link">Thousands</button>
										<button type="button" class="btn btn-sm btn-link">Millions</button>
										<button type="button" class="btn btn-sm btn-link">Billions</button>
									</div>
								</div>
								<div class="px-3">
									<h6 class="subtitle">Balance Sheet Summary</h6>
								</div>
								<div class="s-table">
									<table class="s-table col-12">
										<thead>
											<tr>
												<th></th>
												<template v-for="(ffss, index) in ratingSelected.analysis.financial_risk.form.ffss">
													<th class="text-center uppercase" style="width: 300px">
														{{  index + 1}}° {{ ffss.ffss_date }} {{ ffss.ffss_cetification_type }} {{ ffss.consolotation_type }}
													</th>
													<th>
													</th>
												</template>
												
											</tr>
											<tr>
												<th></th>
												<template v-for="(ffss, index) in ratingSelected.analysis.financial_risk.form.ffss">
													<th class="text-right uppercase">
														{{ ffss.period }} PARTIAL
													</th>
													<th class="text-right">
														-
													</th>
												</template>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(row, index) in labels.balance.assets">
												<td>{{ row }}</td>
												<template v-for="(ffss, indexf) in ratingSelected.analysis.financial_risk.form.ffss">
													<td class="text-right">
														{{ ffss.balance.assets[index] | numeral('0,0') }} 
													</td>
													<td class="text-right">
														%
													</td>
												</template>
											</tr>
										</tbody>
									</table>
								</div>
								<!-- liabilities -->
								<div class="s-table mt-5">
									<table class="s-table col-12">
										<thead>
											<tr>
												<th></th>
												<template v-for="(ffss, index) in ratingSelected.analysis.financial_risk.form.ffss">
													<th class="text-center uppercase" style="width: 300px">
														{{  index + 1}}° {{ ffss.ffss_date }} {{ ffss.ffss_cetification_type }} {{ ffss.consolotation_type }}
													</th>
													<th>
													</th>
												</template>
												
											</tr>
											
										</thead>
										<tbody>
											<tr v-for="(row, index) in labels.balance.liabilities">
												<td>{{ row }}</td>
												<template v-for="(ffss, indexf) in ratingSelected.analysis.financial_risk.form.ffss">
													<td class="text-right">
														{{ ffss.balance.liabilities[index] | numeral('0,0') }} 
													</td>
													<td class="text-right">
														%
													</td>
												</template>
											</tr>
										</tbody>
									</table>
								</div>

								<div class="px-3 mt-4">
									<h6 class="subtitle mb-0">Income Statement</h6>
								</div>
								<div class="s-table mt-2">
									<table class="s-table col-12">
										<thead>
											<tr>
												<th></th>
												<template v-for="(ffss, index) in ratingSelected.analysis.financial_risk.form.ffss">
													<th class="text-center uppercase" style="width: 300px">
														{{  index + 1}}° {{ ffss.ffss_date }} {{ ffss.ffss_cetification_type }} {{ ffss.consolotation_type }}
													</th>
													<th>
													</th>
												</template>
											</tr>
											<tr>
												<th></th>
												<template v-for="(ffss, index) in ratingSelected.analysis.financial_risk.form.ffss">
													<th class="text-right uppercase">
														{{ ffss.period }} PARTIAL
													</th>
													<th class="text-right">
														-
													</th>
												</template>
											</tr>
											
										</thead>
										<tbody>
											<tr v-for="(row, index) in labels.income">
												<td>{{ row }}</td>
												<template v-for="(ffss, indexf) in ratingSelected.analysis.financial_risk.form.ffss">
													<td class="text-right">
														{{ ffss.income[index] | numeral('0,0') }} 
													</td>
													<td class="text-right">
														%
													</td>
												</template>
											</tr>
										</tbody>
									</table>
								</div>
								
								<div class="px-3 mt-4">
									<h6 class="subtitle mb-0">Cash flow analysis</h6>
								</div>
								<div class="s-table mt-2">
									<table class="s-table col-12">
										<thead>
											<tr>
												<th></th>
												<template v-for="(ffss, index) in ratingSelected.analysis.financial_risk.form.ffss">
													<th class="text-center uppercase" style="width: 300px">
														{{  index + 1}}° {{ ffss.ffss_date }} {{ ffss.ffss_cetification_type }} {{ ffss.consolotation_type }}
													</th>
													<th>
													</th>
												</template>
											</tr>
											<tr>
												<th></th>
												<template v-for="(ffss, index) in ratingSelected.analysis.financial_risk.form.ffss">
													<th class="text-right uppercase">
														{{ ffss.period }} PARTIAL
													</th>
													<th class="text-right">
														-
													</th>
												</template>
											</tr>
											
										</thead>
										<tbody>
											<tr v-for="(row, index) in labels.cash_flow">
												<td>{{ row }}</td>
												<template v-for="(ffss, indexf) in ratingSelected.analysis.financial_risk.form.ffss">
													<td class="text-right">
														{{ ffss.cash_flow[index] | numeral('0,0') }} 
													</td>
													<td class="text-right">
														%
													</td>
												</template>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>

						<div class="mt-2 s-collapse__header collapsed" data-toggle="collapse" data-target="#c_fr_m_2" aria-expanded="true" aria-controls="c_fr_m_2">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Key Metrics Corporate</span></h6>
							</div>
						</div>
						
						<div id="c_fr_m_2" class="collapse show" aria-labelledby="c_fr_m_2">

							<div class="s-collapse__header collapsed mt-3" data-toggle="collapse" data-target="#c_fr_m_2_1" aria-expanded="true" aria-controls="c_fr_m_2_1">
								<div class="d-flex">
									<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>SOLVENCY</span></h6>
								</div>
							</div>
							
							<div id="c_fr_m_2_1" class="collapse show" aria-labelledby="c_fr_m_2_1">
								<div class="s-table mt-2">
									<table class="s-table col-12">
										<thead>
											<tr>
												<th></th>
												<template v-for="(ffss, index) in ratingSelected.analysis.financial_risk.form.ffss">
													<th class="text-center uppercase" style="width: 300px">
														{{  index + 1}}° {{ ffss.ffss_date }} {{ ffss.ffss_cetification_type }} {{ ffss.consolotation_type }}
													</th>
													<th>
													</th>
												</template>
												
											</tr>
											
										</thead>
										<tbody>
											<tr v-for="(row, index) in labels.solvency">
												<td>{{ row }}</td>
												<template v-for="(ffss, indexf) in ratingSelected.analysis.financial_risk.form.ffss">
													<td class="text-right">
														{{ ffss.solvency[index] | numeral('0,0') }} 
													</td>
													<td class="text-right">
														%
													</td>
												</template>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							
							<div class="s-collapse__header collapsed mt-3" data-toggle="collapse" data-target="#c_fr_m_2_2" aria-expanded="true" aria-controls="c_fr_m_2_2">
								<div class="d-flex">
									<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>LIQUIDITY</span></h6>
								</div>
							</div>
							
							<div id="c_fr_m_2_2" class="collapse show" aria-labelledby="c_fr_m_2_2">
								<div class="s-table mt-2">
									<table class="s-table col-12">
										<thead>
											<tr>
												<th></th>
												<template v-for="(ffss, index) in ratingSelected.analysis.financial_risk.form.ffss">
													<th class="text-center uppercase" style="width: 300px">
														{{  index + 1}}° {{ ffss.ffss_date }} {{ ffss.ffss_cetification_type }} {{ ffss.consolotation_type }}
													</th>
												</template>
												
											</tr>
											
										</thead>
										<tbody>
											<tr v-for="(row, index) in labels.liquidity">
												<td>{{ row }}</td>
												<template v-for="(ffss, indexf) in ratingSelected.analysis.financial_risk.form.ffss">
													<td class="text-right">
														{{ ffss.liquidity[index] | numeral('0,0') }} 
													</td>
													
												</template>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							
							<div class="s-collapse__header collapsed mt-3" data-toggle="collapse" data-target="#c_fr_m_2_3" aria-expanded="true" aria-controls="c_fr_m_2_3">
								<div class="d-flex">
									<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>PROFITABILITY</span></h6>
								</div>
							</div>
							
							<div id="c_fr_m_2_3" class="collapse show" aria-labelledby="c_fr_m_2_3">
								<div class="s-table mt-2">
									<table class="s-table col-12">
										<thead>
											<tr>
												<th></th>
												<template v-for="(ffss, index) in ratingSelected.analysis.financial_risk.form.ffss">
													<th class="text-center uppercase" style="width: 300px">
														{{  index + 1}}° {{ ffss.ffss_date }} {{ ffss.ffss_cetification_type }} {{ ffss.consolotation_type }}
													</th>
													
												</template>
												
											</tr>
											
										</thead>
										<tbody>
											<tr v-for="(row, index) in labels.profitability">
												<td>{{ row }}</td>
												<template v-for="(ffss, indexf) in ratingSelected.analysis.financial_risk.form.ffss">
													<td class="text-right">
														{{ ffss.profitability[index] | numeral('0,0') }} 
													</td>
													
												</template>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							
							<div class="s-collapse__header collapsed mt-3" data-toggle="collapse" data-target="#c_fr_m_2_4" aria-expanded="true" aria-controls="c_fr_m_2_4">
								<div class="d-flex">
									<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>ACTIVITY</span></h6>
								</div>
							</div>
							
							<div id="c_fr_m_2_4" class="collapse show" aria-labelledby="c_fr_m_2_4">
								<div class="s-table mt-2">
									<table class="s-table col-12">
										<thead>
											<tr>
												<th></th>
												<template v-for="(ffss, index) in ratingSelected.analysis.financial_risk.form.ffss">
													<th class="text-center uppercase" style="width: 300px">
														{{  index + 1}}° {{ ffss.ffss_date }} {{ ffss.ffss_cetification_type }} {{ ffss.consolotation_type }}
													</th>
													
												</template>
												
											</tr>
											
										</thead>
										<tbody>
											<tr v-for="(row, index) in labels.activity">
												<td>{{ row }}</td>
												<template v-for="(ffss, indexf) in ratingSelected.analysis.financial_risk.form.ffss">
													<td class="text-right">
														{{ ffss.activity[index] | numeral('0,0') }} 
													</td>
													
												</template>
											</tr>
										</tbody>
									</table>
								</div>
							</div>

						</div>


						<div class="mt-2 s-collapse__header collapsed" data-toggle="collapse" data-target="#c_fr_m_3" aria-expanded="true" aria-controls="c_fr_m_3">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Capital Structure</span></h6>
							</div>
						</div>
						
						<div id="c_fr_m_3" class="collapse show" aria-labelledby="c_fr_m_3">
							<div class="d-flex flex-wrap">
								<div class="col-4 py-3">
									<p class="m-0">Debit maturity profile</p>
									<input class="form-control" type="text" v-model="formData.debt_maturity_profile">
								</div>
								<div class="col-4 py-3">
									<p class="m-0">Market Risk</p>
									<input class="form-control" type="text" v-model="formData.market_risk">
								</div>
								<div class="col-4 py-3">
									<p class="m-0">Counter Party Credit Risk</p>
									<input class="form-control" type="text" v-model="formData.counter_party_credit_bank">
								</div>
								<div class="col-4 py-3">
									<p class="m-0">Copital Structure Assessment</p>
									<input class="form-control" type="text" v-model="formData.capital_structure_assessment">
								</div>
							</div>
						</div>
						<div class="mt-2 s-collapse__header collapsed" data-toggle="collapse" data-target="#c_fr_m_4" aria-expanded="true" aria-controls="c_fr_m_4">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Off-Balance Sheet</span></h6>
							</div>
						</div>
						
						<div id="c_fr_m_4" class="collapse show" aria-labelledby="c_fr_m_4">
							<div class="d-flex flex-wrap">
								<div class="col-4 py-3">
									<p class="m-0">OFF Blance Sheet Risk</p>
									<input class="form-control" type="text" v-model="formData.off_balance_sheet_risk">
								</div>
								<div class="col-12 py-3">
									<p class="m-0">Off Balance Sheet Assessment</p>
									<input class="form-control" type="text" v-model="formData.off_balance_sheet_assessment">
								</div>
							</div>
						</div>
						<div class="mt-2 s-collapse__header collapsed" data-toggle="collapse" data-target="#c_fr_m_5" aria-expanded="true" aria-controls="c_fr_m_5">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Financial Flexibility and Liquidity</span></h6>
							</div>
						</div>
						
						<div id="c_fr_m_5" class="collapse show" aria-labelledby="c_fr_m_5">
							<div class="d-flex flex-wrap">
								<div class="col-4 py-3">
									<p class="m-0">Access to Additional Funding or Capital</p>
									<input class="form-control" type="text" v-model="formData.access_to_additional_founding_or_capital">
								</div>
								<div class="col-4 py-3">
									<p class="m-0">Need to Additional Funding or Capital</p>
									<input class="form-control" type="text" v-model="formData.need_for_additional_founding_or_capital">
								</div>
							</div>
							<div class="d-flex flex-wrap">
								<div class="col-4 py-3">
									<p class="m-0">Financial Flebility & Liquidity Score</p>
									<input class="form-control" type="text" v-model="formData.financial_flexibility_liquidity_score">
								</div>
							</div>
							<div class="d-flex flex-wrap">
								<div class="col-4 py-3">
									<p class="m-0">Financial Flebility & Liquidity Score Assessment</p>
									<input class="form-control" type="text" v-model="formData.financial_flexibility_liquidity_score_assessment">
								</div>
							</div>
							<div class="d-flex flex-wrap">
								<div class="col-4 py-3">
									<p class="m-0">Undrawn debt</p>
									<input class="form-control" type="text" v-model="formData.undrawn_debt">
								</div>
							</div>
						</div>
						
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="s-btn s-btn--outline" data-dismiss="modal">Cancelar</button>
					<button type="button" class="s-btn s-btn--primary" data-dismiss="modal" @click="save">Guardar</button>
				</div>
			</div>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.charts{
	max-height: 125px !important;
	min-height: 125px !important;
	height: 125px !important;
}
.btn-group{
	.btn{
		&:first-child{
			border-top-left-radius: 0.25rem !important;
			border-bottom-left-radius: 0.25rem !important;
		}
		&:last-child{
			border-top-right-radius: 0.25rem !important;
			border-bottom-right-radius: 0.25rem !important;
		}
	}
}
.btn{
	&-primary{
		background-color: #007cde !important;
	}
}
.button{
	background: transparent !important;
}
.uppercase{
	text-transform: uppercase;
}
</style>